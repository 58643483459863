import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Button from 'erpcore/components/Button';
import ProgressBar from 'erpcore/components/ProgressBar';
import PresentationEndModal from 'erpcore/components/Presentation/components/PresentationEndModal';
import Timer from 'react-compound-timer';
import {
    getPresentationPhase,
    getPresentationQuestionsMeta
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import './PresentationHeader.scss';
import PresentationCompletion from 'erpcore/components/Presentation/components/PresentationCompletion';
import Svg from 'erpcore/components/Svg';
import * as portals from 'react-reverse-portal';
import { ifMobile } from 'erpcore/utils/utils';
import { useWindowSize } from 'erpcore/utils/Hooks';

const PresentationHeader = ({
    sessionData,
    endSession,
    isSessionStarted,
    sessionStartedAt,
    connectionType,
    prospectName,
    portalScreenPublisher
}) => {
    const dispatch = useDispatch();
    const [windowWidth] = useWindowSize();
    const [mobileContentOpened, setMobileContentOpened] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const presentationPhase = useSelector(getPresentationPhase);
    const presentationQuestionsMeta = useSelector(getPresentationQuestionsMeta) || {};

    let initialTimerTime = 0;
    if (sessionStartedAt) {
        initialTimerTime = moment(new Date()).diff(moment.utc(sessionStartedAt), 'miliseconds');
    }

    const triggerEndDemo = () => {
        if (presentationPhase !== 'ended') {
            dispatch({
                type: presentationActions.SET_PRESENTATION_PHASE,
                phase: 'ended'
            });
        } else {
            // retry
            endSession();
        }
    };

    const questionsCompleted = presentationQuestionsMeta?.completed;
    const questionsTotal = presentationQuestionsMeta?.total;

    const displayProspectName = connectionType === 'one-to-one' && prospectName;

    const renderScreenPublisherPortal = ifMobile(windowWidth);

    return (
        <>
            <div
                className="presentation-header"
                data-mobile-content-opened={!displayProspectName || mobileContentOpened ? '1' : '0'}
            >
                {displayProspectName && (
                    <button
                        type="button"
                        onClick={() => {
                            setMobileContentOpened(!mobileContentOpened);
                        }}
                        className="presentation-header__info"
                    >
                        <span className="presentation-header__label">Presentation for</span>
                        <span className="presentation-header__title">{prospectName}</span>
                        <Svg className="presentation-header__toggle" icon="arrowLeft" />
                    </button>
                )}
                {!!renderScreenPublisherPortal && (
                    <div className="presentation-header__screenshare">
                        <portals.OutPortal node={portalScreenPublisher} />
                    </div>
                )}
                <div className="presentation-header__center">
                    <div className="presentation-header__center-col">
                        <PresentationCompletion sessionData={sessionData} />
                    </div>
                    {questionsTotal > 0 && (
                        <div className="presentation-header__center-col">
                            <p className="presentation-header__label">
                                Prospect questions answered
                            </p>
                            <div className="presentation-header__completion">
                                <p className="presentation-header__completion-value">
                                    {questionsCompleted || 0}/{questionsTotal || 0}
                                </p>
                                <div className="presentation-header__completion-bar">
                                    <ProgressBar
                                        percentage={(questionsCompleted / questionsTotal) * 100}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!!isSessionStarted && (
                    <div className="presentation-header__time">
                        <p className="presentation-header__label">Presentation time</p>
                        <span className="presentation-header__title">
                            <Timer
                                lastUnit="h"
                                initialTime={initialTimerTime}
                                timeToUpdate={1000}
                                formatValue={value => {
                                    return value.toString().padStart(2, '0');
                                }}
                            >
                                <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                            </Timer>
                        </span>
                    </div>
                )}
                {!!isSessionStarted && (
                    <div className="presentation-header__end">
                        <Button
                            onClick={() => setModalOpened(true)}
                            label="END DEMO"
                            size="small"
                        />
                    </div>
                )}
            </div>
            {!!modalOpened && (
                <PresentationEndModal
                    confirmAction={triggerEndDemo}
                    cancelAction={() => setModalOpened(false)}
                />
            )}
        </>
    );
};

PresentationHeader.defaultProps = {
    sessionData: null,
    endSession: null,
    connectionType: null,
    isSessionStarted: false,
    sessionStartedAt: null,
    prospectName: null,
    portalScreenPublisher: null
};

PresentationHeader.propTypes = {
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    endSession: PropTypes.func,
    connectionType: PropTypes.string,
    isSessionStarted: PropTypes.bool,
    sessionStartedAt: PropTypes.string,
    prospectName: PropTypes.string,
    portalScreenPublisher: PropTypes.oneOfType([PropTypes.object])
};

export default PresentationHeader;
