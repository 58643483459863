import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ProspectList from 'erpcore/components/Presentation/components/ProspectList';
import PresentationQuestions from 'erpcore/components/Presentation/components/PresentationQuestions';
import PresentationProspectAnswers from 'erpcore/components/Presentation/components/PresentationProspectAnswers';
import PresentationNotes from 'erpcore/components/Presentation/components/PresentationNotes';
import PresentationProspectSessions from 'erpcore/components/Presentation/components/PresentationProspectSessions';
import Svg from 'erpcore/components/Svg';

import {
    getPresentationSection,
    getPresentationCurrentProject,
    getGlobalGuidelines,
    getPresentationPhase
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

import './PresentationAdministration.scss';
import moment from 'moment-timezone';
import PresentationStart from 'erpcore/components/Presentation/components/PresentationStart';
import * as portals from 'react-reverse-portal';
import { ifMobile } from 'erpcore/utils/utils';
import { useWindowSize } from 'erpcore/utils/Hooks';
import Tooltip from 'erpcore/components/Tooltip';

// import Chip from 'erpcore/components/Chip';

const projectSectionMap = {
    'project-home': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    'demo-tour-overview': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    neighbourhood: {
        title: 'Neighbourhood',
        erpKey: 'neighbourhood'
    },
    'building-design': {
        title: 'Building design',
        erpKey: 'building-design'
    },
    floorplans: {
        title: 'Floor plans',
        erpKey: 'floor-plans'
    },
    'home-tour': {
        title: 'Home tour',
        erpKey: 'home-tour'
    },
    'features-and-options': {
        title: 'Features & Options',
        erpKey: 'features-and-options'
    },
    developer: {
        title: 'Developer',
        erpKey: 'developer'
    },
    'purchase-calculator': {
        title: 'Purchase Calculator',
        erpKey: 'purchase-calculator'
    },
    documents: {
        title: 'Documents',
        erpKey: 'documents'
    }
};

const PresentationAdministration = ({
    sessionHelper,
    sessionData,
    publishScreenshare,
    portalScreenPublisher,
    connectionType
}) => {
    const dispatch = useDispatch();
    const [windowWidth] = useWindowSize();
    const presentationSection = useSelector(getPresentationSection);
    const presentationCurrentProject = useSelector(getPresentationCurrentProject);
    const globalGuidelines = useSelector(getGlobalGuidelines);
    const presentationPhase = useSelector(getPresentationPhase);
    const [activeColumn, setActiveColumn] = useState(1);

    const QuestionsContainerRef = useRef(null);
    const ProspectsContainerRef = useRef(null);
    const [activeQuestionsTab, setActiveQuestionsTab] = useState('current-section');
    const questionsTabs = [
        { value: 'current-section', label: 'Current Section' },
        { value: 'all-questions', label: 'All Questions' },
        { value: 'answered-questions', label: 'Answered Questions' }
    ];

    const profileSectionRef = useRef(null);
    const tipsSectionRef = useRef(null);

    const { timezone, date } = {
        ...sessionData
    };

    const { session_prospects: sessionProspects } = {
        ...sessionData
    };
    const sessionProspect = { ...sessionProspects?.[0] };

    const { prospect } = { ...sessionProspect };

    const getGlobalGuidelinesData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: presentationActions.START_FETCHING_GLOBAL_GUIDELINES,
                promise: { resolve, reject }
            });
        });
    };

    useEffect(() => {
        getGlobalGuidelinesData();
    }, []);

    const getCurrentProjectData = () => {
        return sessionData?.deal?.project;
        //  For multiple projects
        // eslint-disable-next-line no-unreachable
        if (sessionData?.projects) {
            return sessionData?.projects.find(item => item.iri === presentationCurrentProject);
        }
        return null;
    };

    const getProjectSectionTips = (project, section) => {
        if (!section || !project) return null;

        const { tools_and_tips: toolsAndTips = [] } = { ...project };
        const sectionTips = toolsAndTips?.filter(
            item => item.section === projectSectionMap?.[section]?.erpKey
        );
        if (sectionTips?.length) {
            return sectionTips.map(item => item.tip);
        }

        return null;
    };

    const renderProjectSpecificGuidelines = () => {
        const currentProjectData = getCurrentProjectData();
        if (currentProjectData) {
            const projectSectionTips = getProjectSectionTips(
                currentProjectData,
                presentationSection
            );
            if (projectSectionTips?.length) {
                return projectSectionTips.map((item, i) => {
                    return (
                        <div
                            key={i} // eslint-disable-line react/no-array-index-key
                            className="presentation-administration__body-item"
                            dangerouslySetInnerHTML={{ __html: item }}
                        />
                    );
                });
            }
        }
        return null;
    };

    const getGlobalGuidelinesBySection = (sectionKey = null, guidelinesData = []) => {
        if (!guidelinesData?.length || !sectionKey) return null;

        const guidelinesBySection = guidelinesData.filter(item => item?.section === sectionKey);

        if (guidelinesBySection?.length) {
            return guidelinesBySection;
        }

        return null;
    };

    const renderGlobalGuidelines = () => {
        const erpKey = projectSectionMap?.[presentationSection]?.erpKey || null;
        const sectionGuidelines = getGlobalGuidelinesBySection(erpKey, globalGuidelines);

        if (sectionGuidelines?.length) {
            return sectionGuidelines.map((item, i) => {
                return (
                    <div
                        key={i} // eslint-disable-line react/no-array-index-key
                        className="presentation-administration__body-item"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                );
            });
        }

        return null;
    };

    /*
    const renderProjectsChips = () => {
        if (!sessionData?.projects?.length) {
            return null;
        }

        return (
            <Chip.Wrapper>
                {sessionData.projects.map(project => {
                    const { iri, name } = {
                        ...project
                    };

                    return (
                        <Chip key={iri} isActive emphasise={iri === presentationCurrentProject}>
                            {name}
                        </Chip>
                    );
                })}
            </Chip.Wrapper>
        );
    };
    */

    const renderSectionTitleTabs = (tabs, activeState) => (
        <div className="presentation-administration__section-title-tabs">
            {tabs.map(tab => (
                <button
                    type="button"
                    key={`profileTab-${tab.value}`}
                    className={`presentation-administration__section-title-tab${
                        tab.value === activeState
                            ? ' presentation-administration__section-title-tab--active'
                            : ''
                    }`}
                    onClick={() => {
                        if (QuestionsContainerRef?.current) {
                            QuestionsContainerRef.current.scrollTop = 0;
                        }
                        setActiveQuestionsTab(tab.value);
                    }}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );

    const renderProspectNotes = () => {
        const { notes = [] } = { ...sessionData?.deal };

        if (!notes?.length) return null;

        return (
            <ul>
                {notes.map((note, i) => {
                    return (
                        <li
                            key={i} // eslint-disable-line react/no-array-index-key
                            dangerouslySetInnerHTML={{ __html: note?.comment }}
                        />
                    );
                })}
            </ul>
        );
    };

    const toggleVisibility = el => {
        el.setAttribute('data-closed', el.getAttribute('data-closed') === '1' ? '0' : '1');
    };

    const renderScreenPublisherPortal = !ifMobile(windowWidth) && presentationPhase === 'intro';

    return (
        <div className="presentation-administration">
            <div className="presentation-administration__content">
                {/* INTRO */}
                {presentationPhase === 'intro' && (
                    <div className="presentation-administration__compact">
                        {!!date && (
                            <p className="presentation-administration__compact-date">
                                <span className="presentation-administration__compact-date-title">
                                    Scheduled for
                                </span>
                                <span className="presentation-administration__compact-date-value">
                                    {moment(date).format('MMM Do, YYYY h:mma ')}
                                    {timezone && (
                                        <Tooltip content={timezone.title}>
                                            {moment()
                                                .tz(timezone.name)
                                                .format('z')}
                                        </Tooltip>
                                    )}
                                </span>
                            </p>
                        )}
                        <div className="presentation-administration__compact-columns">
                            <div className="presentation-administration__column" data-active="1">
                                <div className="presentation-administration__section">
                                    <div className="presentation-administration__section-content">
                                        <PresentationStart
                                            sessionHelper={sessionHelper}
                                            sessionData={sessionData}
                                            portalScreenPublisher={portalScreenPublisher}
                                            publishScreenshare={publishScreenshare}
                                        />
                                    </div>
                                </div>
                            </div>
                            {!!renderScreenPublisherPortal && (
                                <div
                                    className="presentation-administration__column presentation-administration__column--abstract-bg"
                                    data-active="1"
                                >
                                    <div className="presentation-administration__section">
                                        <div className="presentation-administration__section-content">
                                            <portals.OutPortal node={portalScreenPublisher} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* IN-PROGRESS / STARTED */}
                {presentationPhase !== 'intro' && (
                    <>
                        <div className="presentation-administration__tab-control">
                            <button
                                type="button"
                                onClick={() => {
                                    setActiveColumn(1);
                                }}
                                className="presentation-administration__tab-control-button"
                                data-active={activeColumn === 1 ? '1' : '0'}
                            >
                                {connectionType === 'one-to-one' ? 'Prospect Profile' : 'Prospects'}
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setActiveColumn(2);
                                }}
                                className="presentation-administration__tab-control-button"
                                data-active={activeColumn === 2 ? '1' : '0'}
                            >
                                Tools & Tips
                            </button>
                        </div>
                        <div
                            className="presentation-administration__column"
                            data-active={activeColumn === 1 ? '1' : '0'}
                        >
                            <h3 className="presentation-administration__column-title">
                                Prospect Profile
                            </h3>
                            <div className="presentation-administration__section">
                                <h4 className="presentation-administration__section-title">
                                    Questions
                                    {renderSectionTitleTabs(questionsTabs, activeQuestionsTab)}
                                </h4>
                                <div
                                    className="presentation-administration__section-content"
                                    ref={QuestionsContainerRef}
                                >
                                    <PresentationQuestions
                                        sessionHelper={sessionHelper}
                                        sessionProspect={sessionProspect}
                                        activeTab={activeQuestionsTab}
                                        sessionData={sessionData}
                                    />
                                    <PresentationNotes
                                        sessionHelper={sessionHelper}
                                        sessionData={sessionData}
                                        sessionProspect={sessionProspect}
                                    />
                                </div>
                            </div>
                            <div
                                ref={profileSectionRef}
                                data-closed="0"
                                className="presentation-administration__section"
                            >
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (profileSectionRef?.current) {
                                            toggleVisibility(profileSectionRef?.current);
                                        }
                                    }}
                                    className="presentation-administration__section-title presentation-administration__section-title--button"
                                >
                                    Profile
                                    <Svg
                                        className="presentation-administration__section-title__icon"
                                        icon="arrowLeft"
                                    />
                                </button>
                                <div className="presentation-administration__section-content">
                                    <PresentationProspectSessions
                                        sessionData={sessionData}
                                        prospect={prospect}
                                    />
                                    {/*
                            <h4 className="presentation-administration__section-content-title presentation-administration__section-content-title--uppercase">
                                Projects interested in
                            </h4>
                            {renderProjectsChips()}
                            */}
                                    <PresentationProspectAnswers
                                        sessionProspect={sessionProspect}
                                    />
                                    <h4 className="presentation-administration__section-content-title presentation-administration__section-content-title--uppercase">
                                        Notes
                                    </h4>
                                    <div className="presentation-administration__body">
                                        {renderProspectNotes()}
                                    </div>
                                </div>
                            </div>
                            {connectionType === 'one-to-many' && (
                                <div
                                    className="presentation-administration__section"
                                    ref={ProspectsContainerRef}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (ProspectsContainerRef?.current) {
                                                toggleVisibility(ProspectsContainerRef?.current);
                                            }
                                        }}
                                        className="presentation-administration__section-title presentation-administration__section-title--button"
                                    >
                                        Prospects
                                        <Svg
                                            className="presentation-administration__section-title__icon"
                                            icon="arrowLeft"
                                        />
                                    </button>
                                    <div className="presentation-administration__section-content">
                                        <ProspectList sessionData={sessionData} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className="presentation-administration__column"
                            data-active={activeColumn === 2 ? '1' : '0'}
                        >
                            <h3 className="presentation-administration__column-title">
                                Tools and Tips
                            </h3>
                            <div
                                ref={tipsSectionRef}
                                data-closed="0"
                                className="presentation-administration__section"
                            >
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (tipsSectionRef?.current) {
                                            toggleVisibility(tipsSectionRef?.current);
                                        }
                                    }}
                                    className="presentation-administration__section-title presentation-administration__section-title--button"
                                >
                                    Sales guidelines
                                    <Svg
                                        className="presentation-administration__section-title__icon"
                                        icon="arrowLeft"
                                    />
                                </button>
                                <div className="presentation-administration__section-content">
                                    <h4 className="presentation-administration__section-content-title">
                                        <strong>
                                            {projectSectionMap?.[presentationSection]?.title}
                                        </strong>
                                    </h4>
                                    <div className="presentation-administration__body">
                                        {renderGlobalGuidelines()}
                                    </div>
                                </div>
                            </div>
                            {projectSectionMap?.[presentationSection] && (
                                <div className="presentation-administration__section">
                                    <div className="presentation-administration__section-content">
                                        <h4 className="presentation-administration__section-content-title">
                                            Project Specific{' '}
                                            <strong>
                                                {projectSectionMap?.[presentationSection]?.title}
                                            </strong>
                                        </h4>
                                        <div className="presentation-administration__body">
                                            {renderProjectSpecificGuidelines()}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

PresentationAdministration.defaultProps = {
    connectionType: null,
    sessionHelper: null,
    sessionData: null,
    publishScreenshare: () => {},
    portalScreenPublisher: null
};

PresentationAdministration.propTypes = {
    connectionType: PropTypes.string,
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    publishScreenshare: PropTypes.oneOfType([PropTypes.func]),
    portalScreenPublisher: PropTypes.oneOfType([PropTypes.object])
};

export default PresentationAdministration;
