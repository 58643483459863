import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'erpcore/components/Button';
import {
    getImage,
    getImageFetchingData,
    getImageUrl
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';

const DownloadPDF = ({ iri, version, title, self }) => {
    const dispatch = useDispatch();

    const image = useSelector(state => getImage(state, iri));

    const imageUrl = useSelector(state => getImageUrl(state, iri, version));

    const imageFetchingData = useSelector(state => getImageFetchingData(state));

    const fetchImage = () => {
        return new Promise((resolve, reject) => {
            let fetchNeeded = false;

            if (iri && !imageFetchingData?.[iri]) {
                if (image) {
                    if (image?.iri !== iri) {
                        fetchNeeded = true;
                    }
                } else {
                    fetchNeeded = true;
                }
            }

            if (fetchNeeded) {
                dispatch({
                    promise: { resolve, reject },
                    type: imageManagerActions.START_FETCHING_IMAGE,
                    iri
                });
            }
        });
    };

    useEffect(() => {
        if (iri) {
            fetchImage(iri);
        }
    }, [iri]);

    if (imageUrl) {
        return (
            <Button
                size="large"
                variation="link"
                external={!self}
                self={self}
                href={imageUrl}
                label={title}
            />
        );
    }
    return null;
};

DownloadPDF.defaultProps = {
    iri: null,
    version: null,
    title: 'label',
    self: false
};
DownloadPDF.propTypes = {
    iri: PropTypes.string,
    version: PropTypes.string,
    title: PropTypes.string,
    self: PropTypes.bool
};

export default DownloadPDF;
