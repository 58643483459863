import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Tooltip from 'erpcore/components/Tooltip';
// import NetworkTest, { ErrorNames } from 'opentok-network-test-js';

import PresentationCameraTest from 'erpcore/components/Presentation/components/PresentationCameraTest';
import PresentationJoinForm from 'erpcore/components/Presentation/components/PresentationJoin/components/PresentationJoinForm';
import Logo from 'erpcore/components/Layout/Logo';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import './PresentationJoin.scss';
import moment from 'moment-timezone';
import Image from 'erpcore/components/Image';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import Notification from 'erpcore/components/Notification';
import {
    getCameraAccessDenied,
    getCameraDialogOpened
} from 'erpcore/screens/Presentation/Presentation.selectors';

const PresentationJoin = ({
    sessionTestConnection,
    subType,
    showCameraPublisher,
    sessionData,
    title,
    children
}) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const prospectHash = match?.params?.prospectHash;
    const meData = useSelector(getMeData) || {};
    const cameraAccessDenied = useSelector(getCameraAccessDenied);
    const cameraDialogOpened = useSelector(getCameraDialogOpened);
    const [testErrorConnection, setTestErrorConnection] = useState(null);
    const [testErrorPoorQuality, setTestErrorPoorQuality] = useState(null);
    const [testErrorVideo, setTestErrorVideo] = useState(null);
    const [testErrorAudio, setTestErrorAudio] = useState(null);
    const [testErrorVideoSource, setTestErrorVideoSource] = useState(null);
    const [testErrorAudioSource, setTestErrorAudioSource] = useState(null);

    const { session_prospects: sessionProspects = [] } = {
        ...sessionData
    };

    const disableFormFields = ['admin', 'prospect'].includes(subType);
    const requireFormFields = ['guest', 'spectator'].includes(subType);

    const joinMe = () =>
        new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_JOIN_STATE,
                data: true
            });
        }).catch(error => ({ error }));

    useEffect(() => {
        if (sessionTestConnection && subType !== 'spectator') {
            sessionTestConnection
                .testConnectivity()
                .then(() => {
                    setTestErrorConnection(null);
                    sessionTestConnection
                        .testQuality()
                        .then(results => {
                            const publisherSettings = {};
                            if (results.video.reason) {
                                setTestErrorVideo(results.video.reason);
                            } else {
                                setTestErrorVideo(null);
                            }
                            if (!results.audio.supported) {
                                setTestErrorAudio(results.audio.reason);
                            } else {
                                setTestErrorAudio(null);
                            }
                            if (!publisherSettings.videoSource) {
                                setTestErrorVideoSource('Video source is not available!');
                            } else {
                                setTestErrorVideoSource(null);
                            }
                            if (!publisherSettings.audioSource) {
                                setTestErrorAudioSource('Audio source is not available!');
                            } else {
                                setTestErrorAudioSource(null);
                            }

                            setTestErrorPoorQuality(null);
                        })
                        .catch(error => {
                            setTestErrorPoorQuality(error.toString());
                        });
                })
                .catch(error => {
                    setTestErrorConnection(error.toString());
                });
        }
    }, [sessionTestConnection]);

    const getInitialValues = () => {
        const output = {
            first_name: '',
            last_name: '',
            email: ''
        };

        if (prospectHash && subType === 'prospect') {
            const sessionProspect = sessionProspects.find(item => item.hash === prospectHash);
            if (sessionProspect) {
                const { prospect } = { ...sessionProspect };
                const {
                    first_name: firstNameProspect = '',
                    last_name: lastNameProspect = '',
                    email
                } = {
                    ...prospect
                };
                output.first_name = firstNameProspect;
                output.last_name = lastNameProspect;
                output.email = email;
            }
        } else if (subType === 'admin') {
            const { first_name: firstNameUser = '', last_name: lastNameUser = '', email } = {
                ...meData
            };
            output.first_name = firstNameUser;
            output.last_name = lastNameUser;
            output.email = email;
        }

        return output;
    };

    const renderSessionData = () => {
        if (!sessionData?.iri) return null;

        const { timezone, date, sales_agent: salesAgent } = {
            ...sessionData
        };

        let prospectName = null;
        if (sessionProspects?.length === 1) {
            const { prospect } = { ...sessionProspects?.[0] };
            const { first_name: firstName = '', last_name: lastName = '' } = {
                ...prospect
            };
            prospectName = `${firstName} ${lastName}`;
        }

        return (
            <div className="projects__session">
                {!!(!!date || !!prospectName) && (
                    <p className="projects__session-item">
                        <span className="projects__session-item-title">Demo Session for</span>
                        {prospectName && (
                            <span className="projects__session-item-value">{prospectName}</span>
                        )}
                        {date && (
                            <span className="projects__session-item-value projects__session-item-value--smaller">
                                {moment(date).format('MMM Do, YYYY h:mma')}{' '}
                                {timezone && (
                                    <Tooltip content={timezone.title}>
                                        {moment()
                                            .tz(timezone.name)
                                            .format('z')}
                                    </Tooltip>
                                )}
                            </span>
                        )}
                    </p>
                )}
                {!!salesAgent && (
                    <div className="projects__session-item">
                        <span className="projects__session-item-title">Sales agent</span>
                        <span className="projects__session-item-user">
                            {!!salesAgent?.image && (
                                <div className="projects__session-item-user-logo">
                                    <Image
                                        iri={salesAgent?.image}
                                        version="small"
                                        className="projects__session-item-user-logo-image"
                                    />
                                </div>
                            )}
                            <div className="projects__session-item-user-data">
                                <p className="projects__session-item-user-data-title">
                                    {salesAgent.first_name || ''} {salesAgent.last_name || ''}
                                </p>
                                {!!salesAgent.email && (
                                    <p className="projects__session-item-user-data-email">
                                        <a
                                            href={`mailto:${salesAgent.email}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {salesAgent.email}
                                        </a>
                                    </p>
                                )}
                                {!!salesAgent.phone_number && (
                                    <p className="projects__session-item-user-data-telephone">
                                        {salesAgent.phone_number}
                                    </p>
                                )}
                            </div>
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const testConnectionErrors = [];
    if (testErrorConnection) {
        testConnectionErrors.push({
            type: 'warning',
            data: testErrorConnection
        });
    }
    if (testErrorPoorQuality) {
        testConnectionErrors.push({
            type: 'warning',
            data: testErrorPoorQuality
        });
    }
    if (showCameraPublisher && testErrorVideo) {
        testConnectionErrors.push({
            type: 'warning',
            data: testErrorVideo
        });
    }
    if (showCameraPublisher && testErrorAudio) {
        testConnectionErrors.push({
            type: 'warning',
            data: testErrorAudio
        });
    }
    if (showCameraPublisher && testErrorVideoSource) {
        testConnectionErrors.push({
            type: 'error',
            data: testErrorVideoSource
        });
    }
    if (showCameraPublisher && testErrorAudioSource) {
        testConnectionErrors.push({
            type: 'error',
            data: testErrorAudioSource
        });
    }

    return (
        <div className="presentation-join">
            {(!!cameraDialogOpened || !!cameraAccessDenied) && (
                <div
                    className="presentation-join__permission-notice"
                    data-denied={cameraAccessDenied ? '1' : '0'}
                >
                    <p>Please allow access to your camera and microphone</p>
                </div>
            )}
            <div className="presentation-join__inner">
                <div className="presentation-join__logo">
                    <Logo />
                </div>
                {title && <h3 className="presentation-join__title">{title}</h3>}
                {!!children && <div className="presentation-join__content">{children}</div>}
                <div className="presentation-join__setup">
                    {!!sessionTestConnection && !!showCameraPublisher && (
                        <div className="presentation-join__setup-camera">
                            <PresentationCameraTest sessionTestConnection={sessionTestConnection} />
                        </div>
                    )}

                    <div className="presentation-join__setup-form">
                        <PresentationJoinForm
                            requireFormFields={requireFormFields}
                            disableFormFields={disableFormFields}
                            initialValues={getInitialValues()}
                            onSubmit={joinMe}
                        />
                    </div>
                </div>
                {!!sessionTestConnection && !!testConnectionErrors?.length && (
                    <div className="presentation-join__errors">
                        {testConnectionErrors.map(item => {
                            return (
                                <Notification
                                    identifier={`connection-test-${item.data}`}
                                    title={null}
                                    text={item.data}
                                    type={item.type}
                                />
                            );
                        })}
                    </div>
                )}
                {renderSessionData()}
            </div>
        </div>
    );
};

PresentationJoin.defaultProps = {
    sessionTestConnection: null,
    subType: null,
    showCameraPublisher: false,
    sessionData: null,
    title: 'Welcome to your Interactive Home Tour',
    children: null
};

PresentationJoin.propTypes = {
    sessionTestConnection: PropTypes.oneOfType([PropTypes.object]),
    subType: PropTypes.oneOf(['admin', 'prospect', 'guest', 'spectator']),
    showCameraPublisher: PropTypes.bool,
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default PresentationJoin;
