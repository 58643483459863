import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { preloadScript, createSession } from 'opentok-react';
import * as portals from 'react-reverse-portal';
import UIFx from 'uifx';

import PresentationHeader from 'erpcore/components/Presentation/components/PresentationHeader';
import PresentationSidebar from 'erpcore/components/Presentation/components/PresentationSidebar';
import PresentationSidebarToggle from 'erpcore/components/Presentation/components/PresentationSidebarToggle';
import PresentationScreenShareSubscriber from 'erpcore/components/Presentation/components/PresentationScreenShareSubscriber';
import PresentationCameras from 'erpcore/components/Presentation/components/PresentationCameras';
import PresentationScreenPublisher from 'erpcore/components/Presentation/components/PresentationScreenPublisher';
import PresentationIntro from 'erpcore/components/Presentation/components/PresentationIntro';
import {
    getPresentationSidebarOpened,
    getPresentationMyScreenSize,
    getPresentationMyScreenShareActiveStatus,
    getPresentationPhase,
    getPresentationConnectionUsers,
    getPresentationSessionTerminated
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

import handRaiseMp3 from 'erpcore/assets/sounds/handRaise.mp3';
import kickedMp3 from 'erpcore/assets/sounds/kicked.mp3';
import muteMp3 from 'erpcore/assets/sounds/mute.mp3';
import './Presentation.scss';
import PresentationAdministration from 'erpcore/components/Presentation/components/PresentationAdministration';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import PresentationEnded from 'erpcore/components/Presentation/components/PresentationEnded';
import PresentationForbidden from 'erpcore/components/Presentation/components/PresentationForbidden';
import PresentationLoading from 'erpcore/components/Presentation/components/PresentationLoading';
import { useRouteMatch } from 'react-router-dom';
import { ifMobile } from 'erpcore/utils/utils';
import { useWindowSize } from 'erpcore/utils/Hooks';

/*
https://tokbox.com/developer/sdks/js/reference/ConnectionEvent.html
https://tokbox.com/developer/guides/signaling/js/
https://tokbox.com/developer/sdks/js/reference/Session.html#signal

sessionHelper?.session?.connections => all connections in session

The Session object dispatches a connectionCreated event when a client (including your own) connects to a Session. It also dispatches a connectionCreated event for every client in the session when you first connect. (when your local client connects, the Session object also dispatches a sessionConnected event, defined by the SessionConnectEvent class.)

While you are connected to the session, the Session object dispatches a connectionDestroyed event when another client disconnects from the Session. (When you disconnect, the Session object also dispatches a sessionDisconnected event, defined by the SessionDisconnectEvent class.)
 */

const Presentation = ({ type, rtcData, sessionData }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [sessionHelper, setSessionHelper] = useState(null);
    const [streams, setStreams] = useState([]);
    const [streamNames, setStreamName] = useState([]);
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const [isAlreadyOnCall, setIsAlreadyOnCall] = useState(undefined);
    const [isKicked, setIsKicked] = useState(undefined);
    const meData = useSelector(getMeData) || {};
    const [windowWidth] = useWindowSize();
    const prospectHash = match?.params?.prospectHash;
    const guestHash = match?.params?.guestHash;
    const spectatorHash = match?.params?.spectatorHash;
    const presentationPhase = useSelector(getPresentationPhase);
    const presentationConnectionUsers = useSelector(getPresentationConnectionUsers);
    const sessionTerminated = useSelector(getPresentationSessionTerminated);
    const sidebarOpened = useSelector(getPresentationSidebarOpened);
    const myScreenSize = useSelector(getPresentationMyScreenSize);
    const isScreenshareActive = useSelector(getPresentationMyScreenShareActiveStatus);
    const myScreenSizeRef = useRef(null);
    const sidebarOpenedRef = useRef(null);
    const presentationPhaseRef = useRef(null);
    const presentationConnectionUsersRef = useRef(null);

    const portalCameras = React.useMemo(() => portals.createHtmlPortalNode(), []);
    const portalScreenPublisher = React.useMemo(() => portals.createHtmlPortalNode(), []);

    const handRaiseSound = new UIFx(handRaiseMp3);
    const kickedSound = new UIFx(kickedMp3);
    const muteSound = new UIFx(muteMp3);

    const {
        session_prospects: sessionProspects = [],
        started_at: sessionStartedAt,
        ended_at: sessionEndedAt,
        projects,
        chat_enabled: chatEnabled,
        raising_hands_enabled: raisingHandsEnabled
    } = {
        ...sessionData
    };

    const connectionType = sessionProspects?.length === 1 ? 'one-to-one' : 'one-to-many';

    const projectsCount = projects?.length || 0;

    let prospectName = null;
    if (connectionType === 'one-to-one') {
        const { prospect } = { ...sessionProspects?.[0] };
        const { first_name: firstName = '', last_name: lastName = '' } = {
            ...prospect
        };
        prospectName = `${firstName} ${lastName}`;
    }

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getMyConnection = () => {
        if (sessionHelper?.session) {
            return sessionHelper?.session?.connection;
        }
        return null;
    };

    // eslint-disable-next-line no-unused-vars
    const getOtherConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                if (connection.connectionId !== myConnection.connectionId) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const getOtherNonAdminConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    ['prospect', 'guest', 'spectator'].includes(
                        connectionCustomData?.connectionUserType
                    ) &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const getOtherProspectConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    connectionCustomData?.connectionUserType === 'prospect' &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const getAdminConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (connectionCustomData?.connectionUserType === 'admin') {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const getOtherAdminConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    connectionCustomData?.connectionUserType === 'admin' &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const userAlreadyOnCall = myConnection => {
        let isOnCall = false;

        if (guestHash || spectatorHash) return false;

        const userIdentification = type === 'admin' ? meData?.iri : prospectHash;
        const otherConnections =
            type === 'admin' ? getOtherAdminConnections() : getOtherProspectConnections();
        if (otherConnections?.length) {
            isOnCall = otherConnections.find(connection => {
                return (
                    meData?.iri &&
                    getConnectionCustomData(connection)?.user === userIdentification &&
                    connection.creationTime <= myConnection.creationTime
                );
            });
        }
        return isOnCall;
    };

    const emitScreenSizeSignal = screenSize => {
        if (sessionHelper?.session) {
            if (connectionType !== 'one-to-many') {
                const adminConnections = getAdminConnections();
                if (adminConnections?.length) {
                    adminConnections.forEach(connection => {
                        const data = {
                            ...screenSize,
                            windowWidth: window.innerWidth,
                            windowHeight: window.innerHeight
                        };
                        sessionHelper.session.signal({
                            to: connection,
                            data: JSON.stringify(data),
                            type: 'prospectScreenData'
                        });
                    });
                }
            }
        }
    };

    const emitManualScreenSizeSignal = () => {
        const clientRelevantScreenSizeElement = document.querySelector(
            '.presentation-screenshare__content'
        );
        if (clientRelevantScreenSizeElement) {
            const width = clientRelevantScreenSizeElement.getBoundingClientRect()?.width;
            const height = clientRelevantScreenSizeElement.getBoundingClientRect()?.height;
            if ((width || width === 0) && (height || height === 0)) {
                emitScreenSizeSignal({
                    videoSpaceWidth: Math.round(width),
                    videoSpaceHeight: Math.round(height)
                });
            }
        }
    };

    const emitSidebarToggleSignal = (status = sidebarOpened) => {
        if (connectionType !== 'one-to-many') {
            if (sessionHelper?.session) {
                const adminConnections = getAdminConnections();
                if (adminConnections?.length) {
                    adminConnections.forEach(connection => {
                        const data = {
                            prospectSidebarOpened: status
                        };
                        sessionHelper.session.signal({
                            to: connection,
                            data: JSON.stringify(data),
                            type: 'prospectSidebarOpened'
                        });
                    });
                }
            }
        }
    };

    const emitPhaseSignal = ({
        phase,
        toType = null,
        toConnections = [],
        triggeredByConnectionId = null
    }) => {
        if (sessionHelper?.session) {
            const getTypeConnections = () => {
                if (!['all', 'admin', 'client'].includes(toType)) return [];
                if (toType === 'all') {
                    return getOtherConnections();
                }
                if (toType === 'admin') {
                    return getOtherAdminConnections();
                }
                if (toType === 'client') {
                    return getOtherNonAdminConnections();
                }
                return [];
            };
            const targetConnections = toConnections?.length ? toConnections : getTypeConnections();
            if (targetConnections?.length) {
                targetConnections.forEach(connection => {
                    const data = {
                        phase,
                        triggeredByConnectionId
                    };
                    sessionHelper.session.signal({
                        to: connection,
                        data: JSON.stringify(data),
                        type: 'phaseChange'
                    });
                });
            }
        }
    };

    const updateSingleSession = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_UPDATE_SINGLE_SESSION,
                iri: sessionData?.iri,
                formData
            });
        });
    };

    const setSessionTerminated = status => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_SESSION_TERMINATED,
                data: status
            });
        });
    };

    const setSessionTerminationInProgress = status => {
        dispatch({
            type: presentationActions.SET_SESSION_TERMINATION_IN_PROGRESS,
            data: status
        });
    };

    const notifyErpSessionStarted = () => {
        return updateSingleSession({
            started_at: new Date()
        });
    };

    const notifyErpSessionEnded = () => {
        return updateSingleSession({
            ended_at: new Date()
        });
    };

    const endAdminSession = () => {
        setSessionTerminationInProgress(true);
        notifyErpSessionEnded()
            .then(data => {
                // eslint-disable-next-line camelcase
                if (data?.data?.attributes?.ended_at) {
                    setSessionTerminated(true);
                }
            })
            .finally(() => {
                setSessionTerminationInProgress(false);
            });
    };
    const endProspectSession = () => {
        if (sessionHelper) {
            sessionHelper.disconnect();
            setSessionTerminated(true);
        }
    };

    const fetchSessionData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                iri: sessionData?.iri
            });
        }).catch(error => ({ error }));
    };

    const notifyErpThatProspectHasAttended = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_UPDATE_SESSION_PROSPECT,
                iri,
                formData: {
                    has_attended: true
                }
            });
        }).catch(error => ({ error }));
    };

    const getUserName = () => {
        let output = 'Anonymous';

        if (type === 'client' && prospectHash) {
            const sessionProspect = sessionProspects.find(item => item.hash === prospectHash);
            if (sessionProspect) {
                const { prospect } = { ...sessionProspect };
                const { first_name: firstNameProspect = '', last_name: lastNameProspect = '' } = {
                    ...prospect
                };
                if (firstNameProspect || lastNameProspect) {
                    output = `${firstNameProspect} ${lastNameProspect}`;
                }
            }
        } else if (type === 'admin') {
            const { first_name: firstNameUser = '', last_name: lastNameUser = '' } = { ...meData };
            if (firstNameUser || lastNameUser) {
                output = `${firstNameUser} ${lastNameUser}`;
            }
        } else {
            const myConnectionCustomData = getConnectionCustomData(getMyConnection());
            if (myConnectionCustomData?.userName) {
                output = myConnectionCustomData?.userName;
            }
        }

        return output;
    };

    const getSessionProspectByHash = hash => {
        return sessionProspects.find(item => item.hash === hash);
    };

    const addUserConnection = data => {
        if (
            presentationPhaseRef?.current === 'in-progress' &&
            data?.connectionUserType === 'prospect'
        ) {
            notifyErpThatProspectHasAttended(getSessionProspectByHash(data?.user)?.iri);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.PRESENTATION_ADD_CONNECTION_USER,
                data
            });
        }).catch(error => ({ error }));
    };

    const removeUserConnection = connectionId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.PRESENTATION_REMOVE_CONNECTION_USER,
                data: connectionId
            });
        }).catch(error => ({ error }));
    };

    const setOthersScreenSize = data => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_OTHERS_SCREEN_SIZE,
                othersScreenSize: data
            });
        }).catch(error => ({ error }));
    };

    const setProspectSidebarOpened = status => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_PROSPECT_SIDEBAR_OPENED,
                prospectSidebarOpened: status
            });
        }).catch(error => ({ error }));
    };

    const setCurrentPhase = phase => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_PHASE,
                phase
            });
        }).catch(error => ({ error }));
    };

    const setCurrentSection = section => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_SECTION,
                section
            });
        }).catch(error => ({ error }));
    };

    const setCurrentProject = projectIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_CURRENT_PROJECT,
                currentProject: projectIri
            });
        }).catch(error => ({ error }));
    };

    const getOtherAdminScreenStreams = () => {
        const allStreams = sessionHelper?.streams;
        if (allStreams?.length) {
            const otherStreams = allStreams.filter(stream => {
                const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                return (
                    stream?.videoType !== 'camera' &&
                    stream?.name === 'screenshare' &&
                    streamConnectionCustomData?.connectionUserType === 'admin'
                );
            });
            return otherStreams;
        }
        return null;
    };

    const publishScreenshare = (action = undefined) => {
        const isMyScreenShareActiveNextState = action !== undefined ? action : !isScreenshareActive;

        if (isMyScreenShareActiveNextState) {
            const allStreams = getOtherAdminScreenStreams();
            if (allStreams?.length) {
                allStreams.forEach(item => {
                    item.destroy();
                });
            }
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_MY_SCREEN_SHARE_ACTIVE,
                isMyScreenShareActive: isMyScreenShareActiveNextState
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (!sessionEndedAt && !isEmpty(rtcData) && !sessionHelper) {
            setSessionHelper(
                createSession({
                    ...rtcData,
                    onStreamsUpdated: data => {
                        setStreams(data);
                        setStreamName(data.map(stream => stream?.name));
                    }
                })
            );
        }
    }, [rtcData]);

    const handleChatMessage = message => {
        dispatch({
            type: presentationActions.INCREMENT_COUNT_UNREADED_CHAT_MESSAGES
        });
        dispatch({
            type: presentationActions.STORE_CHAT_MESSAGE,
            response: message
        });
    };

    const handleHandRaise = data => {
        dispatch({
            type: presentationActions.STORE_RAISED_HAND,
            response: data
        });
    };
    const handleClearHandRaise = data => {
        dispatch({
            type: presentationActions.CLEAR_RAISED_HAND,
            response: data
        });
    };

    const saveParticipant = connectionData => {
        let userType = '';
        if (connectionData?.connectionUserType === 'guest') {
            userType = 'guest';
        } else if (connectionData?.connectionUserType === 'spectator') {
            userType = 'audience';
        }

        const participant = {
            type: userType,
            first_name: connectionData?.userName,
            email: connectionData?.email
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_CREATE_PARTICIPANT,
                sessionIri: sessionData?.iri,
                participant
            });
        });
    };

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            // someone connected (including myself)
            sessionHelper.session.on('connectionCreated', data => {
                if (type === 'client') {
                    emitManualScreenSizeSignal();
                    // cant't get latest state from here, using ref
                    emitSidebarToggleSignal(sidebarOpenedRef?.current);
                }

                // if event is fired because of someone else connected
                if (
                    type === 'admin' &&
                    data?.connection?.connectionId !== getMyConnection()?.connectionId
                ) {
                    const connectionCustomData = getConnectionCustomData(data?.connection);
                    if (
                        connectionCustomData?.connectionUserType === 'admin' &&
                        presentationPhaseRef?.current === 'in-progress'
                    ) {
                        // emit 'in-progress' phase if admin connected and current phase is 'in-progress'
                        emitPhaseSignal({
                            phase: 'in-progress',
                            toConnections: [data?.connection],
                            triggeredByConnectionId: getMyConnection()?.connectionId
                        });
                    } else if (
                        ['prospect', 'guest', 'spectator'].includes(
                            connectionCustomData?.connectionUserType
                        )
                    ) {
                        // emit any current phase if prospect connected
                        emitPhaseSignal({
                            phase: presentationPhaseRef?.current,
                            toConnections: [data?.connection],
                            triggeredByConnectionId: getMyConnection()?.connectionId
                        });
                    }
                }

                // if (type === 'admin') {
                if (
                    ['admin', 'prospect', 'guest', 'spectator'].includes(
                        getConnectionCustomData(data?.connection)?.connectionUserType
                    )
                ) {
                    addUserConnection({
                        connectionId: data?.connection?.connectionId,
                        creationTime: data?.connection?.creationTime,
                        ...getConnectionCustomData(data?.connection)
                    });
                }
            });

            // I have connected
            sessionHelper.session.on('sessionConnected', event => {
                if (type === 'admin') {
                    setIsAlreadyOnCall(false);
                }

                if (type === 'client') {
                    const connection = event?.target?.connection;
                    const connectionData = getConnectionCustomData(connection);
                    const { connectionUserType } = connectionData;
                    if (userAlreadyOnCall(connection)) {
                        // prevent user from accessing session call
                        setIsAlreadyOnCall(true);
                        event.target.disconnect();
                        // event.target.connection.destroy();
                    } else {
                        setIsAlreadyOnCall(false);
                        emitManualScreenSizeSignal();
                        // cant't get latest state from here, using ref
                        emitSidebarToggleSignal(sidebarOpenedRef?.current);
                        //  if user is guest or spectator save me to api
                        if (connectionUserType === 'spectator' || connectionUserType === 'guest') {
                            saveParticipant(connectionData);
                        }
                    }
                }
            });

            // someone disconnected (including myself)
            sessionHelper.session.on('connectionDestroyed', data => {
                // if (type === 'admin') {
                removeUserConnection(data?.connection?.connectionId);
            });

            // signal event listeners for admin
            if (type === 'admin') {
                sessionHelper.session.on('signal:prospectScreenData', event => {
                    if (event?.data) {
                        setOthersScreenSize(JSON.parse(event?.data));
                    }
                });
                sessionHelper.session.on('signal:prospectSidebarOpened', event => {
                    const data = JSON.parse(event?.data);
                    if (data) {
                        setProspectSidebarOpened(data?.prospectSidebarOpened);
                    }
                });
                sessionHelper.session.on('signal:phaseChange', event => {
                    const data = JSON.parse(event?.data);
                    if (data) {
                        // eslint-disable-next-line no-unused-vars
                        const { phase, triggeredByConnectionId } = { ...data };
                        setCurrentPhase(phase);
                    }
                });
                sessionHelper.session.on('signal:presentationSection', event => {
                    const data = JSON.parse(event?.data);
                    if (data) {
                        setCurrentSection(data?.section);
                    }
                });
                sessionHelper.session.on('signal:presentationCurrentProject', event => {
                    const data = JSON.parse(event?.data);
                    if (data) {
                        setCurrentProject(data?.currentProject);
                    }
                });
            }

            // signal event listeners for prospect
            if (type === 'client') {
                sessionHelper.session.on('signal:phaseChange', event => {
                    const data = JSON.parse(event?.data);
                    if (data) {
                        setCurrentPhase(data?.phase);
                    }
                });

                sessionHelper.session.on('signal:muteSelf', () => {
                    muteSound.play(0.5);
                });

                sessionHelper.session.on('signal:kickUser', event => {
                    event.target.connection.destroy();
                    kickedSound.play(0.5);
                    setIsKicked(true);
                });
            }

            // chat
            sessionHelper.session.on('signal:chatSubmitted', event => {
                const messageData = JSON.parse(event.data);
                const owner =
                    event.from.connectionId === sessionHelper.session.connection.connectionId;
                handleChatMessage({
                    owner,
                    ...messageData
                });
            });
            sessionHelper.session.on('signal:raiseHandSubmitted', event => {
                if (type === 'admin') {
                    const messageData = JSON.parse(event.data);
                    handRaiseSound.play(0.5);
                    const fromHash = JSON.parse(event.from.data);
                    handleHandRaise({
                        owner: `${fromHash.user}${messageData.author}`,
                        ...messageData
                    });
                }
            });
            sessionHelper.session.on('signal:clearRaiseHandSubmitted', event => {
                const messageData = JSON.parse(event.data);
                const { owner } = { ...messageData };
                handleClearHandRaise({
                    owner
                });
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    useEffect(() => {
        sidebarOpenedRef.current = sidebarOpened;
        if (type === 'client') {
            emitSidebarToggleSignal(sidebarOpened);
        }
    }, [sidebarOpened]);

    useEffect(() => {
        myScreenSizeRef.current = myScreenSize;
        if (type === 'client') {
            emitScreenSizeSignal(myScreenSize);
        }
    }, [myScreenSize]);

    useEffect(() => {
        presentationConnectionUsersRef.current = presentationConnectionUsers;
    }, [presentationConnectionUsers]);

    useEffect(() => {
        presentationPhaseRef.current = presentationPhase;
        if (type === 'admin') {
            if (presentationPhase === 'in-progress') {
                emitPhaseSignal({
                    triggeredByConnectionId: getMyConnection()?.connectionId,
                    phase: presentationPhase,
                    toType: 'all'
                });
            } else {
                emitPhaseSignal({
                    phase: presentationPhase,
                    toType: 'client'
                });
            }
        }
        if (type === 'admin' && presentationPhase === 'in-progress') {
            notifyErpSessionStarted().then(() => {
                fetchSessionData();
                if (presentationConnectionUsersRef.current?.length) {
                    presentationConnectionUsersRef.current.forEach(item => {
                        if (['prospect', 'guest', 'spectator'].includes(item?.connectionUserType)) {
                            notifyErpThatProspectHasAttended(
                                getSessionProspectByHash(item?.user)?.iri
                            );
                        }
                    });
                }
            });
        }
        if (type === 'client' && presentationPhase === 'in-progress') {
            emitManualScreenSizeSignal();
        }
        if (presentationPhase === 'ended') {
            if (type === 'admin') {
                endAdminSession();
            }
            if (type === 'client') {
                endProspectSession();
            }
        }
    }, [presentationPhase]);

    // on un-mount
    /*
    useEffect(() => {
        return () => {
            if (sessionHelper) {
                // sessionHelper.disconnect();
            }
        };
    }, []);
    */

    // Expired/Ended for Admin
    if (type === 'admin' && (sessionTerminated || sessionEndedAt)) {
        return <PresentationEnded sessionData={sessionData} title="Session has ended!" />;
    }

    const renderTitle = () => {
        if (prospectHash) return 'Thank you for participating in our Interactive Home Tour.';

        if (spectatorHash) return 'Thank you for joining in our Home Tour.';

        if (guestHash) return 'Thank you for participating in our Interactive Home Tour.';

        return null;
    };

    // Expired/Ended for Prospect
    if (type === 'client') {
        if (sessionTerminated) {
            return (
                <PresentationEnded sessionData={sessionData} showSessionInfo title={renderTitle()}>
                    {!!prospectHash && (
                        <p>
                            We appreciate you taking the time to be part of the experience. You will
                            be receiving a follow up email shortly that will include a personalized
                            link that will allow you to revisit your Interactive Home Tour.
                            <br />
                            Thank you again for your time and we look forward to speaking with you
                            soon.
                        </p>
                    )}

                    {!!guestHash && (
                        <p>
                            We appreciate you taking the time to be part of the experience. We will
                            be following up with you in the coming days to ensure any additional
                            questions you may have are answered and walk you through any items that
                            you wish to spend more time on.
                            <br />
                            Thank you again for your time and we look forward to speaking with you
                            soon.
                        </p>
                    )}

                    {!!spectatorHash && (
                        <p>
                            We appreciate you taking the time to be part of the experience. We will
                            be following up with you in the coming days to ensure any questions you
                            may have are answered, walk you through any items that you wish to spend
                            more time on, or book an interactive home tour.
                            <br />
                            Thank you again for your time and we look forward to speaking with you
                            soon.
                        </p>
                    )}
                </PresentationEnded>
            );
        }
        if (sessionEndedAt) {
            return (
                <PresentationEnded
                    sessionData={sessionData}
                    showSessionInfo
                    title="This session has expired"
                >
                    <p>
                        Thank you for taking the time to revisit your Interactive Home Tour. Your
                        personalized post tour link has expired.
                        <br />
                        If you would like to book an additional Interactive Home Tour or have
                        questions about the community of interest to you, please contact our Sales
                        Representative listed below. Please contact your Sales Agent for any further
                        assistance and information.
                        <br />
                        We look forward to connecting with you again soon.
                    </p>
                </PresentationEnded>
            );
        }
    }

    if (isKicked) {
        return (
            <PresentationEnded sessionData={sessionData} title="">
                <p>
                    Uh Oh! It appears the Presenter has removed you from the Home Tour. If you wish
                    to participate in another Interactive Home Tour, please reach out directly to
                    the Sales Representative by phone or email.
                </p>
                <p>We apologize for the inconvenience and hope to connect with you again soon.</p>
            </PresentationEnded>
        );
    }

    if (isAlreadyOnCall === undefined) {
        return <PresentationLoading />;
    }

    if (isAlreadyOnCall === true) {
        return (
            <PresentationForbidden
                title="You are already in this session right now on a different window/device!"
                message="You can't have multiple session windows open."
            />
        );
    }

    const showCameraPublisher = type === 'admin' || (type === 'client' && !spectatorHash);

    const renderMobileCameras = !!ifMobile(windowWidth);

    return (
        <div
            className={`presentation presentation--${type} presentation--${connectionType}`}
            data-phase={presentationPhase}
        >
            <portals.InPortal node={portalCameras}>
                <PresentationCameras
                    type={type}
                    userName={getUserName()}
                    getMyConnection={getMyConnection}
                    getAdminConnections={getAdminConnections}
                    sessionHelper={sessionHelper}
                    showCameraPublisher={showCameraPublisher}
                    chatEnabled={chatEnabled}
                    raisingHandsEnabled={raisingHandsEnabled}
                />
            </portals.InPortal>
            <portals.InPortal node={portalScreenPublisher}>
                <PresentationScreenPublisher
                    isScreenshareActive={isScreenshareActive}
                    getMyConnection={getMyConnection}
                    publishScreenshare={publishScreenshare}
                    sessionHelper={sessionHelper}
                    getOtherNonAdminConnections={getOtherNonAdminConnections}
                    connectionType={connectionType}
                />
            </portals.InPortal>
            {!!sessionHelper && !!renderMobileCameras && (
                <div className="presentation-cameras-mobile">
                    <portals.OutPortal
                        theme={type === 'admin' ? 'light' : 'dark'}
                        node={portalCameras}
                    />
                </div>
            )}
            {type === 'admin' && (
                <PresentationHeader
                    sessionData={sessionData}
                    endSession={endAdminSession}
                    isSessionStarted={sessionStartedAt || presentationPhase === 'in-progress'}
                    sessionStartedAt={sessionStartedAt}
                    prospectName={prospectName}
                    connectionType={connectionType}
                    projectsCount={projectsCount}
                    portalScreenPublisher={portalScreenPublisher}
                />
            )}
            {type === 'client' && presentationPhase === 'intro' && (
                <PresentationIntro
                    type={type}
                    sessionHelper={sessionHelper}
                    streams={streams}
                    streamNames={streamNames}
                    sessionData={sessionData}
                    connectionUsers={presentationConnectionUsers}
                    getMyConnection={getMyConnection}
                    renderCameras={!renderMobileCameras}
                    portalCameras={portalCameras}
                    portalScreenPublisher={portalScreenPublisher}
                    publishScreenshare={publishScreenshare}
                    chatEnabled={chatEnabled}
                    getUserName={getUserName}
                />
            )}
            {((type === 'client' && presentationPhase !== 'intro') || type === 'admin') && (
                <>
                    <div className="presentation-main">
                        {type === 'client' && (
                            <div className="presentation-main__device-advice">
                                <p>For best experience please use the tablet or desktop device</p>
                            </div>
                        )}
                        {type === 'client' && <PresentationSidebarToggle />}
                        <PresentationSidebar
                            type={type}
                            sessionHelper={sessionHelper}
                            streamNames={streamNames}
                            sessionData={sessionData}
                            renderCameras={!renderMobileCameras}
                            portalCameras={portalCameras}
                            portalScreenPublisher={portalScreenPublisher}
                            publishScreenshare={publishScreenshare}
                            getUserName={getUserName}
                            getOtherAdminConnections={getOtherAdminConnections}
                            chatEnabled={chatEnabled}
                            raisingHandsEnabled={raisingHandsEnabled}
                            presentationPhase={presentationPhase}
                        />
                        {type === 'client' && (
                            <PresentationScreenShareSubscriber
                                sessionHelper={sessionHelper}
                                streams={[...streams]}
                                streamNames={streamNames}
                            />
                        )}
                        {type === 'admin' && (
                            <PresentationAdministration
                                connectionType={connectionType}
                                sessionHelper={sessionHelper}
                                sessionData={sessionData}
                                publishScreenshare={publishScreenshare}
                                portalScreenPublisher={portalScreenPublisher}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

Presentation.defaultProps = {
    type: 'client',
    rtcData: null,
    sessionData: null
};

Presentation.propTypes = {
    type: PropTypes.oneOf(['client', 'admin']),
    rtcData: PropTypes.oneOfType([PropTypes.object]),
    sessionData: PropTypes.oneOfType([PropTypes.object])
};

export default preloadScript(Presentation);
