import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import ElementLoader from 'erpcore/components/ElementLoader';
import './Button.scss';

/**
 *
 * @param {*} props
 */
class Button extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.button = React.createRef();
    }

    handleIcon() {
        const { label, labelOnlyAria, iconName, loading } = this.props;

        if (iconName && iconName !== '') {
            return (
                <Fragment>
                    {loading && <ElementLoader />}
                    <Svg className="button__icon" icon={iconName} />
                    {label !== '' && !labelOnlyAria && (
                        <span className="button__label">{label}</span>
                    )}
                </Fragment>
            );
        }

        if (loading && (!iconName || iconName === '')) {
            return (
                <Fragment>
                    <ElementLoader />{' '}
                    {!labelOnlyAria && <span className="button__label">{label}</span>}
                </Fragment>
            );
        }

        if (!labelOnlyAria) {
            return label;
        }

        return null;
    }

    handleVersions() {
        const { variation, className, size, disabled, loading } = this.props;

        this.className = `button ${className}`;

        // Variation
        if (variation && variation === 'secondary') {
            this.className = `${this.className} button--secondary`;
        } else {
            this.className = this.className.replace(' button--secondary', '');
        }

        if (variation && variation === 'tertiary') {
            this.className = `${this.className} button--tertiary`;
        } else {
            this.className = this.className.replace(' button--tertiary', '');
        }

        if (variation && variation === 'link') {
            this.className = `${this.className} button--link`;
        } else {
            this.className = this.className.replace(' button--link', '');
        }

        if (variation && variation === 'action') {
            this.className = `${this.className} button--action`;
        } else {
            this.className = this.className.replace(' button--action', '');
        }

        if (variation && variation === 'table-action') {
            this.className = `${this.className} button--table-action`;
        } else {
            this.className = this.className.replace(' button--table-action', '');
        }

        // Size
        if (size && size === 'small') {
            this.className = `${this.className} button--small`;
        } else {
            this.className = this.className.replace(' button--small', '');
        }

        if (size && size === 'large') {
            this.className = `${this.className} button--large`;
        } else {
            this.className = this.className.replace(' button--large', '');
        }

        if (disabled) {
            this.className = `${this.className} button--disabled`;
        } else {
            this.className = this.className.replace(' button--disabled', '');
        }

        if (loading) {
            this.className = `${this.className}  button--loading`;
        } else {
            this.className = this.className.replace(' button--loading', '');
        }

        return this.className;
    }

    renderElement() {
        const { href, disabled, submit, form, label, onClick, external, self } = this.props;

        // generate aria label string
        let ariaLabel = '';
        if (label && (typeof label === 'string' || label instanceof String)) {
            ariaLabel = label;
        }

        if (href && href !== '') {
            if (external) {
                return (
                    <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={ariaLabel}
                        className={this.className || ''}
                        ref={this.button}
                        onClick={onClick}
                    >
                        {this.handleIcon()}
                    </a>
                );
            }
            if (self) {
                return (
                    <a
                        href={href}
                        target="_self"
                        rel="noopener noreferrer"
                        aria-label={ariaLabel}
                        className={this.className || ''}
                        ref={this.button}
                        onClick={onClick}
                    >
                        {this.handleIcon()}
                    </a>
                );
            }

            return (
                <Link
                    to={href}
                    aria-label={ariaLabel}
                    className={this.className || ''}
                    ref={this.button}
                    onClick={onClick}
                >
                    {this.handleIcon()}
                </Link>
            );
        }

        if (submit) {
            return (
                <button
                    type="submit"
                    form={form}
                    aria-label={ariaLabel}
                    className={this.className || ''}
                    disabled={disabled}
                    ref={this.button}
                    onClick={onClick}
                >
                    {this.handleIcon()}
                </button>
            );
        }

        return (
            <button
                type="button"
                aria-label={ariaLabel}
                className={this.className || ''}
                disabled={disabled}
                ref={this.button}
                onClick={onClick}
            >
                {this.handleIcon()}
            </button>
        );
    }

    render() {
        this.handleVersions();

        return this.renderElement();
    }
}

Button.defaultProps = {
    variation: 'primary',
    size: null,
    className: '',
    href: '',
    external: false,
    label: 'Button',
    labelOnlyAria: false,
    iconName: '',
    disabled: false,
    loading: false,
    submit: false,
    onClick: null,
    form: null,
    self: false
};

Button.propTypes = {
    variation: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'link',
        'action',
        'table-action'
    ]),
    size: PropTypes.oneOf(['small', 'large']),
    className: PropTypes.string,
    href: PropTypes.string,
    external: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelOnlyAria: PropTypes.bool,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    submit: PropTypes.bool,
    onClick: PropTypes.func,
    form: PropTypes.string,
    self: PropTypes.bool
};

export default Button;
