import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const questionsDefaultParams = 'order_by[position]=asc&pagination=false';
const sessionDefaultParams =
    'include=deal,deal.project,deal.project.developmentProjectLogo,sessionProspects,salesAgent.image,projects,projects.developmentProjectLogo,projects.developmentProjectLogo.versions,projects.city,projects.state,sessionProspects.prospect,timezone';

// const rtcUrl = enviromentVariables.REACT_APP_RTC_API;
// const env = enviromentVariables.REACT_APP_ENV || 'dev';
const appSlug = enviromentVariables.REACT_APP_SLUG || 'mla';

const encodeRoomName = (identifier, isTest) => {
    return btoa(
        `${window.location.hostname}-${appSlug}-${identifier}${isTest ? '-test-connection' : ''}`
    )
        .split('/')
        .join('SOLIDUS');
};

const sortQuestions = questions => {
    if (Array.isArray(questions) && questions?.length) {
        return questions.sort((a, b) => {
            if (a?.position && b?.position) {
                return a?.position - b?.position;
            }

            if (a?.position && b?.position === null) {
                return 1;
            }

            if (b?.position && a?.position === null) {
                return 1;
            }

            if (a?.position === null && b?.position === null) {
                return parseInt(a?.id, 10) - parseInt(b?.id, 10);
            }

            return 0;
        });
    }

    return questions;
};

/*
export function* fetchRtcRoomDataOLD({
    promise,
    identifier,
    connectionData,
    openTokRole = 'publisher',
    isTest
}) {
    try {
        if (!rtcUrl) throw { error: '.env file is missing REACT_APP_RTC_API' }; // eslint-disable-line no-throw-literal
        if (!identifier) throw { error: 'roomName is not defined' }; // eslint-disable-line no-throw-literal
        const fetchRtcRoomDataAPI = yield axios({
            method: 'get',
            withCredentials: false,
            url: `${rtcUrl}/room/${encodeRoomName(identifier, isTest)}`,
            headers: {},
            params: { user_data: { ...connectionData }, role: openTokRole }
        });
        yield put({
            type: presentationActions.FETCHING_PRESENTATION_RTC_DATA_SUCCESSFUL
        });
        yield put({
            type: isTest
                ? presentationActions.SET_PRESENTATION_RTC_DATA_TEST
                : presentationActions.SET_PRESENTATION_RTC_DATA,
            rtcData: fetchRtcRoomDataAPI?.data
        });
        yield call(promise.resolve, fetchRtcRoomDataAPI?.data);
    } catch (error) {
        yield put({
            type: presentationActions.FETCHING_PRESENTATION_RTC_DATA_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}
*/

/**
 * Fetch fetchRtcRoomData
 * @param  {string|int} sessionId
 * @param  {object} connectionData
 * @param  {string} openTokRole
 * @param  {boolean} isTest
 * @return {Promise} Promise with response from API
 */
export function* fetchRtcRoomData({
    promise,
    sessionId,
    connectionData = {},
    openTokRole = 'publisher',
    isTest
}) {
    try {
        const fetchRtcRoomDataAPI = yield restClient.get(
            `/api/sessions/${sessionId}/videocall-session`,
            {
                params: {
                    room_name: encodeRoomName(sessionId, isTest),
                    user_data: JSON.stringify({ ...connectionData }),
                    role: openTokRole
                }
            }
        );
        yield put({
            type: presentationActions.FETCHING_PRESENTATION_RTC_DATA_SUCCESSFUL
        });
        yield put({
            type: isTest
                ? presentationActions.SET_PRESENTATION_RTC_DATA_TEST
                : presentationActions.SET_PRESENTATION_RTC_DATA,
            rtcData: fetchRtcRoomDataAPI?.data
        });
        yield call(promise.resolve, fetchRtcRoomDataAPI?.data);
    } catch (error) {
        yield put({
            type: presentationActions.FETCHING_PRESENTATION_RTC_DATA_FAILED,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* fetchSession({ promise, iri, hash, params }) {
    try {
        const apiEndpoint = iri || `/api/sessions-by-hash/${hash}`;
        const fetchSessionAPI = yield restClient.get(`${apiEndpoint}?${sessionDefaultParams}`, {
            params
        });
        const tokenForSpread = yield hash && fetchSessionAPI?.data?.meta?.token
            ? { token: fetchSessionAPI.data.meta.token }
            : null;
        const included = yield [
            ...(fetchSessionAPI?.data?.included || []).map(item => {
                return { ...{}, ...item };
            })
        ];
        const dtoFetchSessionAPI = dto(fetchSessionAPI?.data);
        yield put({
            type: presentationActions.FETCH_SINGLE_SESSION_SUCCESSFUL
        });

        const projectID =
            // eslint-disable-next-line camelcase
            dtoFetchSessionAPI?.data?.deal?.elastic_search_override_fields_data?.project?.id;
        const data = {
            ...dtoFetchSessionAPI?.data,
            ...tokenForSpread,
            // because yea, for one; backend can't include third level relation, and for two; DTO needs refactor
            sales_agent: {
                // eslint-disable-next-line camelcase
                ...dtoFetchSessionAPI?.data?.sales_agent,
                image: included.find(
                    // eslint-disable-next-line camelcase
                    include => include.id === dtoFetchSessionAPI?.data?.sales_agent?.iri
                    // eslint-disable-next-line camelcase
                )?.relationships?.image?.data?.id
            },
            projects: dtoFetchSessionAPI?.data?.projects?.length
                ? dtoFetchSessionAPI.data.projects.map(item => {
                      return {
                          ...item,
                          featured_image: included.find(
                              include => include.id === item.iri
                              // eslint-disable-next-line camelcase
                          )?.relationships?.featured_image?.data?.id
                      };
                  })
                : [],
            deal: {
                ...dtoFetchSessionAPI?.data?.deal,
                project: {
                    ...(dtoFetchSessionAPI?.data?.['deal.project']?.data?.attributes || null),
                    // ...included.filter(item => item?.id?.includes(projectID))[0].attributes
                    id: projectID,
                    iri: `/api/projects/${projectID}`
                }
            }
        };

        yield put({
            type: presentationActions.STORE_SINGLE_SESSION_DATA,
            [iri ? 'iri' : 'hash']: hash || iri,
            response: {
                data
            }
        });
        yield call(promise.resolve, dtoFetchSessionAPI);
    } catch (error) {
        yield put({
            type: presentationActions.FETCH_SINGLE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: error?.response?.data?.code } || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Session single data
 * @param  {Object} Session Iri
 * @return {Object} Response from API
 */
export function* updateSingleSession({ promise, formData, iri }) {
    try {
        const updateSessionAPI = yield restClient.patch(`${iri}?${sessionDefaultParams}`, formData);
        const included = yield [
            ...(updateSessionAPI?.data?.included || []).map(item => {
                return { ...{}, ...item };
            })
        ];
        const dtoUpdateSessionAPI = dto(updateSessionAPI?.data);
        yield put({
            type: presentationActions.UPDATE_SINGLE_SESSION_SUCCESSFUL
        });
        yield put({
            type: presentationActions.STORE_SINGLE_SESSION_DATA,
            iri,
            response: {
                data: {
                    ...dtoUpdateSessionAPI?.data,
                    // because yea, for one; backend can't include third level relation, and for two; DTO needs refactor
                    sales_agent: {
                        // eslint-disable-next-line camelcase
                        ...dtoUpdateSessionAPI?.data?.sales_agent,
                        image: included.find(
                            // eslint-disable-next-line camelcase
                            include => include.id === dtoUpdateSessionAPI?.data?.sales_agent?.iri
                            // eslint-disable-next-line camelcase
                        )?.relationships?.image?.data?.id
                    },
                    projects: dtoUpdateSessionAPI?.data?.projects?.length
                        ? dtoUpdateSessionAPI.data.projects.map(item => {
                              return {
                                  ...item,
                                  featured_image: included.find(
                                      include => include.id === item.iri
                                      // eslint-disable-next-line camelcase
                                  )?.relationships?.featured_image?.data?.id
                              };
                          })
                        : []
                }
            }
        });

        yield call(promise.resolve, updateSessionAPI?.data);
    } catch (error) {
        yield put({
            type: presentationActions.UPDATE_SINGLE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Increment post presentation prospect view count
 * @param  {Object} Session Iri
 * @return {Object} Response from API
 */
export function* incrementProspectPostPresentationViewCount({ promise, iri, token }) {
    try {
        // const updateSessionAPI = yield restClient.post(`${iri}/increment-view`);

        const updateSessionAPI = yield axios({
            method: 'post',
            withCredentials: false,
            url: `${process.env.REACT_APP_REST_API}${iri}/increment-view`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        yield put({
            type: presentationActions.INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_SUCCESSFUL
        });
        yield call(promise.resolve, updateSessionAPI?.data);
    } catch (error) {
        yield put({
            type: presentationActions.INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Session Prospect
 * @param  {Object} promise
 * @param  {Object} formData
 * @param  {string} iri
 * @return {Object} response from API
 */

export function* updateSessionProspect({ promise, formData, iri }) {
    if (yield !iri) {
        yield call(promise.resolve, null);
    } else {
        try {
            const createSessionAPI = yield restClient.patch(`${iri}`, formData);

            yield put({
                type: presentationActions.UPDATE_SESSION_PROSPECT_SUCCESSFUL
            });
            yield call(promise.resolve, createSessionAPI?.data);
        } catch (error) {
            yield put({
                type: presentationActions.UPDATE_SESSION_PROSPECT_FAILED
            });
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data || error
            });
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Fetch Single Prospect Sessions
 * @param  {Object} Iri of Prospect
 * @return {Object} Response from API
 */
export function* fetchProspectSessions({ promise, iri }) {
    try {
        const fetchAPI = yield restClient.get(
            `/api/session-prospects?filters[prospect][equals]=${iri}&include=session,session.salesAgent`
        );
        const dtoFetchAPI = dto(fetchAPI?.data);
        yield put({
            type: presentationActions.FETCHING_PROSPECT_SESSIONS_SUCCESSFUL,
            iri,
            response: dtoFetchAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.FETCHING_PROSPECT_SESSIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Questions
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* fetchQuestions({ promise, params }) {
    try {
        const fetchQuestionsAPI = yield restClient.get(
            `/api/prospect-profile-questions?${questionsDefaultParams}`,
            {
                params
            }
        );
        const dtoFetchQuestionsAPI = dto(fetchQuestionsAPI?.data);

        const sortedResponse = {
            ...dtoFetchQuestionsAPI,
            data: sortQuestions(dtoFetchQuestionsAPI?.data)
        };

        yield put({
            type: presentationActions.FETCH_QUESTIONS_SUCCESSFUL,
            response: sortedResponse
        });
        yield call(promise.resolve, sortedResponse);
    } catch (error) {
        yield put({
            type: presentationActions.START_FETCH_QUESTIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Global Guidelines
 * @param {Object} promise
 * @return {Object} response from API
 */
export function* fetchGlobalGuidelines({ promise }) {
    try {
        const fetchGlobalGuidelinesAPI = yield restClient.get(`/api/sales-guidelines`);
        const dtoFetchQuestionsAPI = dto(fetchGlobalGuidelinesAPI?.data);
        yield put({
            type: presentationActions.FETCHING_GLOBAL_GUIDELINES_SUCCESSFUL,
            response: dtoFetchQuestionsAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.FETCHING_GLOBAL_GUIDELINES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Prospect Answers
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* fetchProspectAnswers({ promise, iri }) {
    try {
        const fetchAPI = yield restClient.get(
            `/api/profile-answers?pagination=false&include=deal,question,sessionProspect,sessionProspect.prospect&order_by[question.position]=asc`,
            {
                params: {
                    'filters[deal][equals]': iri
                }
            }
        );
        const dtoFetchAPI = yield dto(fetchAPI?.data);
        // filtering manually because at the time of writing this code filtering by prospect didn't work on backend
        const filteredAnswers = (Array.isArray(dtoFetchAPI?.data) ? dtoFetchAPI?.data : []).filter(
            item => {
                return item?.deal?.iri === iri;
            }
        );
        const filteredResponse = { ...dtoFetchAPI, data: filteredAnswers };

        yield put({
            type: presentationActions.FETCH_PROSPECT_ANSWERS_SUCCESSFUL,
            iri,
            response: filteredResponse
        });
        yield call(promise.resolve, filteredResponse);
    } catch (error) {
        yield put({
            type: presentationActions.FETCH_PROSPECT_ANSWERS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Set Prospect Answers
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* setProspectAnswers({ promise, iri, formData }) {
    try {
        const fetchAPI = yield restClient.post('/api/profile-answers', formData);
        const dtoFetchAPI = yield dto(fetchAPI?.data);
        yield restClient.put(formData?.deal, { profile_answers: [dtoFetchAPI?.data?.iri] });
        yield put({
            type: presentationActions.SUBMIT_PROSPECT_ANSWERS_SUCCESSFUL,
            iri,
            response: dtoFetchAPI
        });
        yield call(promise.resolve, dtoFetchAPI);
    } catch (error) {
        yield put({
            type: presentationActions.FETCH_PROSPECT_ANSWERS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Add Prospect Notes
 * @param {Object} promise
 * @param {Array} notes
 * @params {String} sessionIri - Session Iri
 * @params {String} sessionProspectIri - Sessison Prospect Iri
 * @return {Object} response from API
 */
export function* createProspectNote({ promise, notes, sessionDealIri }) {
    try {
        yield restClient.patch(sessionDealIri, { notes });
        yield put({
            type: presentationActions.CREATE_PROSPECT_NOTES_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.CREATE_PROSPECT_NOTES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Add Chat Message
 * @param {Object} promise
 * @param {Array} notes
 * @params {String} sessionIri - Session Iri
 * @params {String} sessionProspectIri - Sessison Prospect Iri
 * @return {Object} response from API
 */
export function* createChatMessage({ promise, sessionIri, message, userType, hash }) {
    try {
        yield restClient.post(
            `/api/chat-messages${userType && hash ? `?${userType}_hash=${hash}` : ''}`,
            {
                content: message.content,
                created_by: message.author,
                session: sessionIri
            }
        );
        yield put({
            type: presentationActions.CREATE_CHAT_MESSAGE_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.CREATE_CHAT_MESSAGE_FAILED
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Get all session chat messages
 * @param {Object} promise
 * @param {Array} notes
 * @params {String} sessionIri - Session Iri
 * @params {String} sessionProspectIri - Sessison Prospect Iri
 * @return {Object} response from API
 */
export function* fetchChatMessages({ promise, sessionIri, userType, hash }) {
    try {
        const fetchAPI = yield restClient.get(
            `/api/chat-messages?filters[session][equals]=${sessionIri}&pagination=false${
                userType && hash ? `&${userType}_hash=${hash}` : ''
            }`
        );
        yield put({
            type: presentationActions.FETCHING_CHAT_MESSAGES_SUCCESSFUL,
            response: dto(fetchAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.FETCHING_CHAT_MESSAGES_FAILED
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Get all session chat messages
 * @param {Object} promise
 * @param {Array} participant

 */
export function* saveParticipants({ promise, sessionIri, participant }) {
    try {
        yield restClient.post('/api/session/guests', {
            session: sessionIri,
            ...participant
        });
        yield put({
            type: presentationActions.CREATE_PARTICIPANT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: presentationActions.CREATE_PARTICIPANT_FAILED
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(presentationActions.START_FETCHING_PRESENTATION_RTC_DATA, fetchRtcRoomData),
    takeLatest(presentationActions.START_FETCHING_SINGLE_SESSION, fetchSession),
    takeLatest(presentationActions.START_UPDATE_SINGLE_SESSION, updateSingleSession),
    takeLatest(
        presentationActions.START_INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT,
        incrementProspectPostPresentationViewCount
    ),
    takeLatest(presentationActions.START_FETCHING_PROSPECT_SESSIONS, fetchProspectSessions),
    takeLatest(presentationActions.START_UPDATE_SESSION_PROSPECT, updateSessionProspect),
    takeLatest(presentationActions.START_FETCHING_QUESTIONS, fetchQuestions),
    takeLatest(presentationActions.START_FETCHING_PROSPECT_ANSWERS, fetchProspectAnswers),
    takeLatest(presentationActions.START_SUBMIT_PROSPECT_ANSWERS, setProspectAnswers),
    takeLatest(presentationActions.START_FETCHING_GLOBAL_GUIDELINES, fetchGlobalGuidelines),
    takeLatest(presentationActions.START_CREATE_PROSPECT_NOTES, createProspectNote),
    takeLatest(presentationActions.START_CREATE_CHAT_MESSAGE, createChatMessage),
    takeLatest(presentationActions.START_FETCHING_CHAT_MESSAGES, fetchChatMessages),
    takeLatest(presentationActions.START_CREATE_PARTICIPANT, saveParticipants)
];
